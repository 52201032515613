<template>
	<div class="grid flex-grow-1">
		<div class="col-4">
			<div class="flex flex-column" style="height:100%">
				<div>
					<img src="images/logo-moldam.png" />
					<p>Software tool for obtaining process parameters, simulation and mechanical and termal properties for the materials for 
					direct extrusion additive manufacturing process.</p>
				</div>
				<div class="flex-grow-1">
				</div>
				<div>
					<img style="width: 200px; " src="images/logo-tecnalia.svg" />
				</div>
			</div>
		</div>
		<div class="col-8">
			<div class="card flex flex-column" style="background-image: url('images/home-bg.png'); background-size: cover; height: 100%">
				<div>
				<h5>Home</h5>
				<p>Select the desired action.</p>
				</div>
				<div class="grid flex-grow-1 align-items-center">
					<div class="col-4">
						<Button @click="$router.push('process-data')" label="Process data" class="p-button-warning" style="width:100%" />
					</div>
					<div class="col-4">
						<Button @click="$router.push('simulation')" label="Simulation" class="p-button-warning" style="width:100%" />
					</div>
					<div class="col-4">
						<Button @click="$router.push('material-properties')" label="Material properties" class="p-button-warning" style="width:100%" />
					</div>
				</div>
				<div class="grid">
					<div class="col-6" style="font-size: 12px">
						Parque Tecnológico de San Sebastián<br>
						Mikeletegi Pasealekua, 7<br>
						E-20009 Donostia-San Sebastián - Gipuzkoa (Spain)<br>
						+34 946 430 850<br>
						administrator@tecnalia.com
					</div>
					<div class="col-6 text-right">
						<img src="images/logo-eit.png" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>